<template>
  <b-tab :title="getTitle">
    <div class="line-holder">
      <div class="line w-100" />
      <div class="line glow-line">
      </div>
    </div>
    <div class="mt-2" :class="tasksWidget ? 'col' : 'row'">

      <div v-if="preLoader" class="col-12">
        <b-spinner large label="Large Spinner" />
      </div>

      <div v-else-if="tasks.length === 0" class="pt-3 px-4 col-12">
        <p class="no-task-found mt-3">{{ $t('search_no_tasks_message') }}</p>
      </div>

      <div v-else :class="tasksWidget ? 'mb-3' : 'col-lg-3 col-md-4 col-sm-6 col-12 mb-3'" v-for="(task, index) in paginated" :key="index">
        <div class="d-flex bg-gray">
          <div class="mr-auto p-2 align-self-center">
            <div class="small">{{ $t('status') }}:</div>
            <span v-if="task.status === 1" class="badge badge-success text-wrap">{{ $t('active') }}</span>
            <span v-else-if="task.status === 2" class="badge badge-warning text-wrap">{{ $t('pending') }}</span>
            <span v-else-if="task.status === 3" class="badge badge-danger text-wrap">{{ $t('declined_button') }}</span>
            <span v-else-if="task.status === 4" class="badge badge-dark text-wrap">{{ $t('unlisted') }}</span>
            <span v-else class="badge badge-secondary text-wrap">{{ $t('draft') }}</span>
          </div>
          <div class="p-2 align-self-center">
            <b-dropdown size="sm" variant="outline-secondary">
              <template slot="button-content">{{ $t('actions') }}</template>
              <template v-if="$store.getters.hasRole('ROLE_SELLER') && $store.getters.getVerification() === 1 && checkIfTaskComplete(task)">
                <b-dropdown-item v-if="(task.status === 0 || task.status === 3)" class="dropdown-options" v-on:click="switchTaskStatus(1, task)">
                  {{ $t('activate') }}
                </b-dropdown-item>
                <b-dropdown-item v-else-if="task.status !== 4" class="dropdown-options" v-on:click="switchTaskStatus(0, task)">
                  {{ $t('deactivate') }}
                </b-dropdown-item>
                <b-dropdown-divider v-if="task.status !== 4" class="p-0"/>
              </template>

              <b-dropdown-item v-if="task.status !== 3" class="dropdown-options" v-on:click="$router.push({ name: 'editTask', params: { slug:  task.hash }})">
                <span v-if="task.status !== 3">{{ $t('edit') }}</span>
                <span v-else>{{ $t('why_is_this_declined') }}</span>
              </b-dropdown-item>
              <b-dropdown-item class="dropdown-options" v-on:click="confirmDelete(task)">
                {{ $t('delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <Card :isFeaturedTask="false"
              :deliveryTime="task.packages[0].delivery"
              :carouselImage="task"
              :username="user.username"
              :slug="task.slug"
              :user_rating="user.review_average"
              :user_count="user.review_count"
              :user_online="false"
              :imageIcon="user.photo_path"
              :description="task.title"
              :rating="task.review_average"
              :count="task.review_count"
              :price="task.packages[0].price"
              :hash="task.hash"
              :etn="showEtnPrice(task.packages[0].price)"
              :saveTasksPage=false
              :profilePage=true
              :position="index + 1"
              :sellerInfo="user"
              location="my-tasks"
              :triggerTrackingEvent=false
              :primaryCategorySlug="task.categories[0] === undefined ? null : task.categories[0].slug "
              :isHeroTask="task.hero"
              :tasksWidget="tasksWidget"
              class="mx-auto my-sm-0 w-100 no-actions"
              v-bind:class="{'card-disable': task.slug === null}"
        />
      </div>
    </div>
    <div class="row">
      <Pagination v-if="tasks.length > pagination.perPage" :perPage="pagination.perPage" :maxPageOverride="tasksWidget ? 3 : null" :currentPage="pagination.current" :total="tasks.length - 1" v-on:paginationUpdate="updatePage" class="col-12"/>
    </div>
  </b-tab>
</template>

<script>
import Card from '../../Task/Card'
import Pagination from '@/components/Global/Pagination'
import ApiService from '@/apiService'

export default {
  name: 'TasksTab',
  components: {
    Card,
    Pagination
  },
  props: {
    title: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    tasksWidget: {
      type: Boolean,
      required: false
    },
    tasks: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      pagination: {
        current: 1,
        perPage: this.tasksWidget ? 5 : 20
      },
      preLoader: false,
      placeholderImage: 'https://images.anytask.com/image/upload/c_scale,h_220,w_330/c_scale,e_blackwhite,l_urbiknsbdw7r5mpntg5w,o_35,w_150/v1580733311/uerbsfwhzr3lsgstyv0h.png'
    }
  },
  methods: {
    checkIfTaskComplete (task) {
      if (task.slug == null || task.categories.length === 0 || task.assets.length === 0) {
        return false
      }
      let taskPackage = task.packages[0]
      if (taskPackage.price === 0 || taskPackage.delivery === 0 || taskPackage.revisions === 0) {
        return false
      }
      return true
    },
    updatePage (page) {
      this.preLoader = true
      this.pagination.current = page
      setTimeout(() => {
        const taskContainer = document.getElementsByClassName('tasks-container')[0]
        if (taskContainer) {
          taskContainer.scrollTo(0, 0)
        } else {
          window.scrollTo(0, 0)
        }
        this.preLoader = false
      }, 2)
    },
    showEtnPrice (price) {
      return (price / this.getEtnRate).toFixed(2)
    },
    switchTaskStatus (status, task) {
      this.active = status
      this.$swal({
        title: this.$t('sure'),
        text: this.active === 0 ? this.$t('deactivate_warning') : this.$t('activate_warning'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.active === 0 ? this.$t('deactivate_action') : this.$t('activate_action'),
        cancelButtonText: this.$t('discard_action'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ApiService.gigActivation(task.hash, { 'status': status }).then((response) => {
            return response.data.data[0]
          }).catch((error) => {
            this.$swal.showValidationMessage(
              '<p class="mb-0">' + error.response.data.error_message + '</p>'
            )
          })
        }
      }).then((result) => {
        if (typeof result.value === 'object') {
          this.$swal(this.active === 0 ? this.$t('deactivated') : this.$t('activated'), this.active === 0 ? this.$t('deactivate_confirm') : this.$t('activate_confirm'), 'success')
          task.status = result.value.status
        }
      })
    },
    confirmDelete (task) {
      this.$swal({
        title: this.$t('sure'),
        text: this.$t('delete_warning'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm_delete'),
        cancelButtonText: this.$t('do_not_delete'),
        showCloseButton: true,
        preConfirm: () => {
          ApiService.deleteTask(task.hash).then(() => { }).catch((error) => {
            this.$swal.showValidationMessage(
              '<p class="mb-0">' + error.response.data.error_message + '</p>'
            )
          })
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal(
            this.$t('deleted'),
            this.$t('delete_confirmed'),
            'success'
          )
          task.status = -1
        }
      })
    }
  },
  computed: {
    getEtnRate () {
      return this.$store.getters.getCurrencyRates()['USD'].etn_rate
    },
    getTitle () {
      return this.$t(this.title, { 'count': this.tasks.length })
    },
    indexStart () {
      return (this.pagination.current - 1) * this.pagination.perPage
    },
    indexEnd () {
      return this.indexStart + this.pagination.perPage
    },
    paginated () {
      return this.tasks.slice(this.indexStart, this.indexEnd)
    }
  }
}
</script>

<style scoped>
.card-disable {
  pointer-events: none;
}
</style>
